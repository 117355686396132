import React, { useRef } from "react";
import { useQuery } from "react-query";
import { Media } from "../types/fhir";
import { Button, Card, Loader, Table, Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import mediaService from "../connection/mediaService";
import { MediaTableItem } from "../components/MediaTableItem/MediaTableItem";
import { showNotification } from "@mantine/notifications";
import sanitizeHtml from "sanitize-html";
import "./MediaListPage.css";
import convertToBase64 from "../helpers/convertToBase64";
import { IExtentionType } from "../types/IQuestionnareItemType";


export const MediaListPage = (): JSX.Element => {

  const {data, isLoading, refetch} = useQuery<Media[] | undefined, Error>("medias", async() => {
    return await mediaService.getAll();
  }, {refetchOnMount: "always"});

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [imgPreview, setImgPreview] = React.useState<string | ArrayBuffer | null>(null);
  const [base64Image, setBase64Image] = React.useState<string | ArrayBuffer | null>(null);
  const [hash, setHash] = React.useState<string | null>(null);
  const [description, setDescription] = React.useState<string>("");

  const {t} = useTranslation();


  const createMedia = (base64Image: string | ArrayBuffer | null, description: string) => {
    if(!base64Image) {
      return;
    }

    const img = new Image();
    img.src = `${base64Image}`;
    img.onload = () => {
      const media: Media = {
        resourceType: "Media",
        status: "completed",
        extension: [
          {
            url: IExtentionType.sha1Hash,
            valueString: hash ?? undefined,
          }
        ],
        width: img.width,
        height: img.height,
        content: {
          contentType: "image/webp",
          data: (base64Image as string).split(',')[1],
        },
        text: {
          status: "additional",
          div: `${description}`
        }
      }
      mediaService.addOne(media).then((response) => {
        showNotification({
          title: t("Media upload"),
          message: response.status == 201 ? t("The media was uploaded") : t("The media failed to upload"),
          color: response.status == 201 ? "green" : "red",
        });
        refetch();
      });

    }
  }

  const SetImageData = (image: HTMLImageElement) => {
    const canvas = document.createElement("canvas");
    const maxWidth = image.width < 512 ? image.width : 512;
    const ratio = maxWidth / image.width;
    canvas.width = image.width * ratio;
    canvas.height = image.height * ratio;
    canvas.getContext("2d")?.drawImage(image, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob as Blob);
      reader.onloadend = () => {
        const buffer = new TextEncoder().encode(reader.result as string);
        crypto.subtle.digest("SHA-1", buffer).then((hash) => {
          setHash(hex(hash));
          setBase64Image(reader.result);
          setImgPreview(reader.result);
        });
      }
    }, "image/webp");
  }

  const OnFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.files) {
      //No file chosen
      return
    }

    convertToBase64(e.target.files[0]).then((base64) => {
      if(!base64) {
        return;
      }
      const image = new Image();
      image.src = base64 as string;
      image.onload = () => {
        SetImageData(image);
      }
    });

  }

  const reset = () => {
    setImgPreview(null);
    setBase64Image(null);
    setHash(null);
    setDescription("");
  }

  return (
      <>
        <Card className="upload-card">

          {!imgPreview && (
              <>
                <Text>{t("Upload new image")}</Text>
                <Button data-cy="button-choose-image" variant="outline"
                        onClick={() => fileRef.current?.click()}>Vælg billede</Button>
                <input
                    type="file"
                    accept={"image/*"}
                    id="file"
                    ref={fileRef}
                    style={{display: "none"}}
                    onChange={(e) => {
                      OnFileSelected(e);
                    }}
                />
              </>
          )}

          {imgPreview && (
              <>
                <Button onClick={() => reset()} variant="outline">{t("Cancel")}</Button>
                <Card shadow="sm"><img data-cy="image-upload-preview" width="256px" src={`${imgPreview}`}
                                       alt={t("image preview")}/></Card>
                <div>
                  <label>{t("Add a description")}</label>
                  <TextInput data-cy="image-description-input" title={t("Description")} onChange={(event) => {
                    setDescription(event.target.value)
                  }}></TextInput>
                </div>
                <Button data-cy="media-upload-button" variant="outline" onClick={() => {
                  createMedia(base64Image, `${description}`);
                  reset();
                }}>Upload</Button>
              </>
          )}

        </Card>

        {isLoading && (
            <Loader variant={"dots"}/>
        )}

        {!isLoading && data?.length === 0 && (
            <Text>{t("No results")}</Text>
        )}

        {data && data.length > 0 && (
            <Table>
              <thead>
              <tr>
                <th>
                  <Text>{t("ID")}</Text>
                </th>
                <th>
                  <Text>{t("Image")}</Text>
                </th>
                <th>
                  <Text>{t("Description")}</Text>
                </th>
                <th>
                  <Text>{t("Unique image id")}</Text>
                </th>
                <th>
                  <Text>{t("Action")}</Text>
                </th>
                <th>
                  <Text>{t("Usage")}</Text>
                </th>
              </tr>
              </thead>
              <tbody>
              {data?.map((media: Media) => {
                return (
                    <MediaTableItem
                        key={media.id}
                        id={media.id ?? ""}
                        height={media.height ?? 0}
                        width={media.width ?? 0}
                        content={media.content.data ?? ""}
                        hash={media.extension?.find((extension) => extension.url === IExtentionType.sha1Hash)?.valueString ?? ""}
                        text={sanitizeHtml(media?.text?.div ?? "")}
                        onDelete={() => {
                          mediaService.removeOne(media.id ?? "").then(() => {
                            refetch();
                          });
                        }
                        }
                    />
                );
              })}
              </tbody>
            </Table>
        )}
      </>
  );
};


function hex(buffer: ArrayBuffer) {
  const hexCodes = [];
  const view = new DataView(buffer);
  for(let i = 0; i < view.byteLength; i += 4) {
    // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
    const value = view.getUint32(i);
    // toString(16) will give the hex representation of the number without padding
    const stringValue = value.toString(16);
    // We use concatenation and slice for padding
    const padding = '00000000';
    const paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }

  // Join all the hex strings into one
  return hexCodes.join("");
}
