import {apiClientService} from "./apiClientService";

const apiGateway = apiClientService.apiGateway;

export type SOR = {
    id: string;
    deprecated: boolean;
    deprecationData?: Date;
    sor: string;
    unitName: string;
};

export type SorSearchReturnData = {
    foundCodes: SOR[],
    nonFoundCodes: string[]
}

const search = async (search: string, selected: string[]): Promise<SOR[] | undefined> => {

    let responseselectedData = {foundCodes: [], nonFoundCodes: []} as SorSearchReturnData;
    if (selected.length > 0) {
        const responseSelected = await apiGateway.post<SorSearchReturnData>("api/SorSearcher/FindSorCodes", {
            codes: selected
        })
        if (responseSelected.data) {
            responseselectedData = responseSelected.data;
        }
    }

    let responseSearchData = [] as SOR[];
    if (search !== "") {
        const responseSearch = await apiGateway.get<SOR[]>("api/SorSearcher/FindSorCode?searchParameter=" + search);
        if (responseSearch.data) {
            responseSearchData = responseSearch.data;
        }
    }

    return responseselectedData.foundCodes.concat(responseSearchData);
};

const sorService = {
    search,
};

export default sorService;
