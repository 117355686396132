import { AxiosResponse } from "axios";
import { Bundle, BundleEntry, Questionnaire } from "../types/fhir";
import { apiClientService } from "./apiClientService";
import { getNextUrl } from "./paging";
import { QuestionnaireItem } from "../types/QuestionnaireItem";
import { showNotification } from "@mantine/notifications";
import { TFunction } from "i18next";

const axiosInstance = apiClientService.axiosClient;
const apiGatewayInstance = apiClientService.apiGateway;

// CRUD operations

const getAll = async (): Promise<Questionnaire[] | undefined> => {
  let response = await axiosInstance.get<Bundle>("/fhir/Questionnaire");

  const questionnaires = new Set<Questionnaire>();

  mapBundleToQuestionnaires(response.data)?.forEach((questionnaire) => {
    questionnaires.add(questionnaire);
  });

  while (getNextUrl(response.data)) {
    const nextUrl = getNextUrl(response.data);
    if (!nextUrl) break;

    response = await axiosInstance.get<Bundle>(nextUrl);
    mapBundleToQuestionnaires(response.data)?.forEach((questionnaire) => {
      questionnaires.add(questionnaire);
    });
  }
  return Array.from(questionnaires);
};

const getAllForOverview = async (): Promise<Questionnaire[] | undefined> => {
  let response = await axiosInstance.get<Bundle>("/fhir/Questionnaire", {
    params: {
      _count: 500,
      _elements: "id,status,version,title,jurisdiction,description,extension",
    },
  });

  const questionnaires = new Set<Questionnaire>();

  mapBundleToQuestionnaires(response.data)?.forEach((questionnaire) => {
    questionnaires.add(questionnaire);
  });

  while (getNextUrl(response.data)) {
    const nextUrl = getNextUrl(response.data);
    if (!nextUrl) break;

    response = await axiosInstance.get<Bundle>(nextUrl);
    mapBundleToQuestionnaires(response.data)?.forEach((questionnaire) => {
      questionnaires.add(questionnaire);
    });
  }
  return Array.from(questionnaires);
};

const getOne = async (id: string): Promise<Questionnaire | undefined> => {
  const response = await axiosInstance.get<Questionnaire>(`/fhir/Questionnaire/${id}`);
  return response.data;
};

const getQuestionnairesUsingMedia = async (mediaId: string): Promise<Questionnaire[] | undefined> => {
  const response = await axiosInstance.get<Bundle>(
    `/fhir/Questionnaire?_elements=name,url,item.extension,item.item.extension,item.item.item.extension,item.item.item.item.extension,item.item.item.item.item.extension,item.item.item.item.item.item.extension,item.item.item.item.item.item.item.extension,item.item.item.item.item.item.item.item.extension,item.item.item.item.item.item.item.item.item.extension,item.item.item.item.item.item.item.item.item.item.extension`
  );
  const questionnaires = mapBundleToQuestionnaires(response.data);
  const filteredQs: Questionnaire[] = [];
  const searchAndAdd = (
    questionnaire: Questionnaire,
    item: QuestionnaireItem[],
    mediaId: string,
    filteredQs: Questionnaire[]
  ) => {
    item.forEach((i) => {
      if (i.extension?.some((ext) => ext.url === "question-helpImage" && ext.valueString?.includes(mediaId))) {
        filteredQs.push(questionnaire);
      }
      if (i.item) {
        searchAndAdd(questionnaire, i.item, mediaId, filteredQs);
      }
    });
  };

  questionnaires?.forEach((q) => {
    searchAndAdd(q ?? "NO_ID_FOUND", q.item ?? [], mediaId, filteredQs);
  });
  return filteredQs;
};

const getByTitle = async (title: string, exact = false): Promise<Questionnaire[] | undefined> => {
  const response = await axiosInstance.get<Bundle>(`/fhir/Questionnaire?title=${title}`);
  const questionnaires = mapBundleToQuestionnaires(response.data);
  if (exact) {
    return questionnaires?.filter((q) => q.title === title);
  }
  return questionnaires;
};

const getByUuid = async (uuid: string | undefined): Promise<Questionnaire[] | undefined> => {
  if (!uuid) return undefined;
  const response = await axiosInstance.get<Bundle>(`/fhir/Questionnaire?uuid=${uuid}`);
  return mapBundleToQuestionnaires(response.data);
};

const addOne = async (questionnaire: Questionnaire): Promise<AxiosResponse<Questionnaire>> => {
  const response = await axiosInstance.post<Questionnaire>("/fhir/Questionnaire", questionnaire);
  return response;
};

const updateOne = async (questionnaire: Questionnaire): Promise<number> => {
  const response = await axiosInstance.put<Questionnaire>(`/fhir/Questionnaire/${questionnaire.id}`, questionnaire);
  return response.status;
};

const removeOne = async (id: string): Promise<number> => {
  const response = await axiosInstance.delete<Questionnaire>(`/fhir/Questionnaire/${id}`);
  return response.status;
};

const GetLatestQuestionnaireByUuid = async (uuid: string, t: TFunction): Promise<Questionnaire | undefined> => {
  const httpResponse = await apiGatewayInstance.get(
    `api/Questionnaire/GetLatestQuestionnaireByUuid?questionnaireUuid=${uuid}`
  );
  if (httpResponse.status > 399) {
    showNotification({
      title: httpResponse.data.title,
      message: httpResponse.data.detail,
      color: "red",
      autoClose: 10000,
    });
    return undefined;
  }

  const response = httpResponse.data as ApiGateWayResponse<string>;
  if (response.error) {
    showNotification({
      title: httpResponse.data.title,
      message: response.error.Message,
      color: "red",
      autoClose: 10000,
    });
    return undefined;
  }

  if (response.data === undefined) {
    showNotification({
      title: t("No results"),
      message: t("There is no data to display"),
      color: "red",
      autoClose: 10000,
    });
    return undefined;
  }

  return JSON.parse(response.data) as Questionnaire;
};

const getQuestionnaireByUuidAndVersion = async (uuid: string, version: string) => {
  const httpResponse = await apiGatewayInstance.get(
    `api/Questionnaire/GetQuestionnaireByUuidAndVersion?questionnaireUuid=${uuid}&version=${version}`
  );
  if (httpResponse.status > 399) {
    console.error(
      `Calling 'GetQuestionnaireByUuidAndVersion' resultet in error ${httpResponse.status}, ${httpResponse.statusText}`
    );
    throw httpResponse.statusText;
  }

  const response = httpResponse.data as ApiGateWayResponse<string>;

  if (response.error) {
    throw new Error(response.error.Message);
  }

  if (response.data === undefined) {
    return undefined;
  }

  const questionnaire = JSON.parse(response.data as unknown as string);

  return questionnaire as Questionnaire;
};

const questionnaireService = {
  getAll,
  getOne,
  getByTitle,
  getByUuid,
  addOne,
  updateOne,
  removeOne,
  getAllForOverview,
  getQuestionnairesUsingMedia,
  getQuestionnaireByUuidAndVersion,
  GetLatestQuestionnaireByUuid,
};

// Mappers

const mapBundleToQuestionnaires = (bundle: Bundle): Questionnaire[] | undefined => {
  return bundle?.entry?.map((entry: BundleEntry) => {
    return JSON.parse(JSON.stringify(entry.resource));
  });
};

export default questionnaireService;

type ApiGateWayResponse<T> = {
  data?: T;
  error?: {
    Message: string;
  };
};
